<template>
  <div>
    <!-- <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else> -->
      <div class="jobs">
        <div class="man-title">
          <span>自定义查询</span>
          <div>
            <el-button style="margin-right: 10px" type="primary" size="small" @click="exportData">导出报表</el-button>
            <el-button @click="addProject" type="primary" size="small">新增项目</el-button>
          </div>
        </div>
        <div class="table-tree">
          <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
            <div class="menu-list">
              <div class="menu-title">
                <i class="el-icon-share"></i>
                <span>分组结构</span>
              </div>
              <div v-if="projectList.length>0">
                <div class="tree-menu" v-for="(item,index) in projectList" :key="index">
                  <div class="tree-menu-title" @click.prevent="projectToggle(item)">
                    <div>
                      <span class="icon-triangle" :class="item.disable?'arrow':'unarrow'"></span>
                      <span>
                        <i class="iconfont icon-xiangmu"></i>
                        {{item.name}}
                      </span>
                    </div>
                    <div style="margin-right: 20px;">
                      <el-dropdown placement="bottom-start" trigger="click">
                        <span @click.stop style="padding: 10px;">
                          <span class="el-dropdown-link">
                            <i class="el-icon-more-outline operate"></i>
                          </span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item>
                            <div @click="addRule(item.customSearchGroupId)">新增规则</div>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <div @click="delGroup(item.customSearchGroupId)">删除分组</div>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <div class="tree-config" v-if="item.disable">
                    <div v-if="item.configList&&item.configList.length>0">
                      <div v-for="(val,key) in item.configList" :key="key">
                        <div class="config-title" :class="current==val.customSearchRuleId?'bright':''" @click="selectRule(val,item)">
                          <div>
                            <span class="class-icon iconfont icon-laiyuan" :style="current==val.customSearchRuleId?'color:#FFF;':'color:#202020;'"></span>
                            <span>{{val.ruleName}}</span>
                          </div>
                          <div>
                            <span class="el-icon-delete" style="color: red;padding: 10px;" :style="current==val.customSearchRuleId?'color:#FFF;':'color:#202020;'" @click.stop="delRule(val.customSearchRuleId)"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else style="width: 100%;text-align: center;line-height: 40px;font-size: 14px;color: #909399;">暂无数据</div>
                  </div>
                </div>
              </div>
              <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
            </div>
          </div>
          <div class="table" style="height: 722px;">
            <div style="height: 722px;" v-if="tableLoading" v-loading="tableLoading" element-loading-spinner="el-icon-loading"></div>
            <div v-else>
              <div style="display: flex;justify-content: flex-start;align-items: center;">
                <div class="search">
                  <div class="title-label">日期类型</div>
                  <div>
                    <el-radio-group v-model="dateSelect" size="small" @change="dateChange" style="padding-top: 4px;">
                      <el-radio-button label="single_day">单日</el-radio-button>
                      <el-radio-button label="last_seven_day">近7日</el-radio-button>
                      <el-radio-button label="last_fifteen_day">近15日</el-radio-button>
                      <el-radio-button label="month">月</el-radio-button>
                      <el-radio-button label="quarter">季</el-radio-button>
                      <el-radio-button label="year">年</el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <div class="search">
                  <div style="margin-left: 15px;">
                    <div v-show="dateSelect=='single_day'">
                      <span class="select-label title-label">选择日期</span>
                      <el-date-picker v-model="date" size="small" type="date" placeholder="选择日期" :clearable="false" value-format="timestamp" :picker-options="pickerOptions" @change="loadRecordTableContent"></el-date-picker>
                    </div>
                    <div v-show="dateSelect=='month'">
                      <span class="select-label title-label">选择月份</span>
                      <el-date-picker v-model="month" size="small" type="month" placeholder="选择月份" :clearable="false" value-format="yyyy-MM" @change="loadRecordTableContent"></el-date-picker>
                    </div>
                    <div v-show="dateSelect=='quarter'">
                      <span class="select-label title-label">选择季度</span>
                      <ElQuarterPicker v-model="quarter" size="small" placeholder="选择季度" :clearable="false" value-format="yyyy-q" @change="loadRecordTableContent"></ElQuarterPicker>
                    </div>
                    <div v-show="dateSelect=='year'">
                      <span class="select-label title-label">选择年份</span>
                      <el-date-picker v-model="year" size="small" type="year" placeholder="选择年份" :clearable="false" value-format="yyyy" @change="loadRecordTableContent"></el-date-picker>
                    </div>
                  </div>
                </div>
                <div class="search" style="height: 54px;">
                  <el-checkbox v-model="tagChecked" :label="`${tagChecked?'隐藏':'显示'}Tag描述`" size="small" border  @change="toggleTagVisiable"></el-checkbox>
                </div>
              </div>
              <el-table ref="tables" :data="tableContent" style="width: 100%;overflow: auto;" height="617">
                <el-table-column prop="equipmentName" label="设备名称" :width="tableContent.length>0?'240':''">
                  <template slot-scope="scope">
                    {{ scope.row.equipmentName ? scope.row.equipmentName : '-' }}
                  </template>
                </el-table-column>
                <el-table-column :width="tableTitleData.length<9?'':'240'" v-for="tItem in tableTitleData" :key="Math.random()" :label="`${tItem.title}`" :prop="`${tItem.index}`"></el-table-column>
              </el-table>
              <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page> -->
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
import ElQuarterPicker from '@/components/ElQuarterPicker.vue';
export default {
  components: {
    page,
    ElQuarterPicker
  },
  data() {
    return {
      tagChecked: false,
      treeLoading: false,
      tableLoading: false,
      size: 50,
      page: 1,
      total: 0,
      form: {},
      formInline: {
        statisticsType: '',
      },
      date: this.getTimestamp(),
      month: null,
      quarter: null,
      year: null,
      dateSelect: 'single_day',

      row: {},
      treeData: [],
      department: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      roleId: 0,

      projectList: [],
      current: null,

      titleArr: [],
      contentArr: [],
      tableTitle: [],
      tableTitleData: [],
      tableContent: [],
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d > n;
        }
      },
    };
  },
  methods: {
    dayjs,
    // 导出报表
    exportData() {
      let date = null
      if(this.dateSelect=='single_day') date = this.date
      if(this.dateSelect=='month') date = this.month
      if(this.dateSelect=='quarter') date = this.quarter
      if(this.dateSelect=='year') date = this.year
      this.tableLoading = true;
      this.$ajax.exportRecord("customDataRuleExport", {
        customSearchRuleId: this.current,
        date: date,
        dateType: this.dateSelect ? this.dateSelect : null,
      }).then((res) => {
        const blob = new Blob([res])
        let custFileName
        if(this.dateSelect=='single_day') custFileName = this.timeStFormat(this.date)
        if(this.dateSelect=='last_seven_day') custFileName = '近7日'
        if(this.dateSelect=='last_fifteen_day') custFileName = '近15日'
        if(this.dateSelect=='month') custFileName = this.month+'月'
        if(this.dateSelect=='quarter') custFileName = this.quarterHanlder()+'季度'
        if(this.dateSelect=='year') custFileName = this.year+'年'
        const fileName = custFileName + '统计报表.xls';
        const linkNode = document.createElement('a');
        linkNode.download = fileName;
        linkNode.style.display = 'none';
        linkNode.href = URL.createObjectURL(blob);
        document.body.appendChild(linkNode);
        linkNode.click();
        URL.revokeObjectURL(linkNode.href);
        document.body.removeChild(linkNode);
        this.tableLoading = false;
        this.$message.success('导出成功')
      }).catch(err=>{
        this.tableLoading = false
        this.$message.error('导出失败')
      })
    },
    toggleTagVisiable(e) {
      if(!e) {
        let arr = this.tableTitle.filter(v=>v.customSearchRuleId)
        this.tableTitleData = arr
      }else {
        this.tableTitleData = this.tableTitle
      }
    },
    // 创建项目
    addProject() {
      this.$router.push({ name: 'iotDataExportAddProject' })
    },
    // 删除项目
    delGroup(customSearchGroupId) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("customDataGroupDelete", {
          customSearchGroupId: customSearchGroupId,
        }).then((res) => {
          this.loadGroupList();
        });
      });
    },
    // 创建规则
    addRule(customSearchGroupId) {
      this.$router.push({ name: 'iotDataExportAdd', params: { customSearchGroupId: customSearchGroupId }})
    },
    // 删除规则
    delRule(customSearchRuleId) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("customDataRuleDelete", {
          customSearchRuleId: customSearchRuleId,
        }).then((res) => {
          this.loadGroupList();
        });
      });
    },
    // 展开折叠项目
    projectToggle(item) {
      item.disable = !item.disable
    },
    // 选择指定规则
    selectRule(val,item) {
      this.tagChecked = false
      this.current = val.customSearchRuleId
      this.getRecordList()
    },
    // 查询项目列表
    loadGroupList() {
      this.treeLoading = true
      this.$ajax.post("customDataGroupQuery",{}).then((res) => {
        let arr = res.data
        if(arr.length==0) {
          this.treeLoading = false
        }
        arr.forEach(v=> {
          v.disable = false
          v.name = v.name
          this.loadRuleList(v)
        })
        this.projectList = arr
      }).catch(err => {
        this.treeLoading = false
      })
    },
    // 获取项目下规则列表
    loadRuleList(conObj) {
      this.$ajax.post('customDataRuleQuery', {
        customSearchGroupId: conObj.customSearchGroupId,
      }).then(res => {
        conObj.configList = res.data
        if(!this.current) {
          this.current = this.projectList[0].configList[0].customSearchRuleId
        }
        this.$forceUpdate()
        this.getRecordList()
        this.treeLoading = false
      }).catch(err=>{
        this.treeLoading = false
      })
    },
    // 获取历史记录列表
    async getRecordList() {
      await this.loadRecordTableTitle()
      await this.loadRecordTableContent()
    },
    // 获取历史记录表头
    loadRecordTableTitle() {
      return new Promise((resolve,reject)=>{
        this.tableLoading = true
        this.$ajax.post('customDataRuleTitle', {
          customSearchRuleId: this.current,
        }).then(res => {
          let arr = []
          res.data.forEach(v=>{
            arr.push({index: 'name'+v.index,title: 'Tag描述'})
            arr.push(v)
          })
          this.tableTitle = arr
          if(!this.tagChecked) {
            this.tableTitleData = this.tableTitle.filter(v=>v.customSearchRuleId)
          }
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.tableLoading = false
        })
      })
    },
    // 获取历史记录内容
    loadRecordTableContent() {
      return new Promise((resolve,reject)=>{
        this.tableLoading = true
        let date = null
        if(this.dateSelect=='single_day') date = this.date
        if(this.dateSelect=='month') date = this.month
        if(this.dateSelect=='quarter') date = this.quarter
        if(this.dateSelect=='year') date = this.year
        this.$ajax.post('customDataRuleContent', {
          customSearchRuleId: this.current,
          date: date,
          dateType: this.dateSelect ? this.dateSelect : "single_day",
        }).then(res => {
          this.contentArr = []
          res.data.forEach(v=>{
            let obj = {
              equipmentName: v.equipmentName
            }
            v.columnInfos.forEach(a=>{
              obj['name'+a.index] = a.tag+'('+a.describe+')'
              obj[a.index] = a.value?a.value:0
            })
            this.contentArr.push(obj)
          })
          this.tableContent = this.contentArr
          this.total = res.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.tableLoading = false
        })
      })
    },
    // 月季年筛选事件
    async dateChange(e) {
      if(e=='single_day') { this.month = null, this.quarter = null, this.year = null, this.date = this.getTimestamp(), await this.loadRecordTableContent() }
      if(e=='last_seven_day') { this.month = null, this.quarter = null, this.year = null, await this.loadRecordTableContent() }
      if(e=='last_fifteen_day') { this.month = null, this.quarter = null, this.year = null, await this.loadRecordTableContent() }
      if(e=='month') { this.quarter = null, this.year = null, this.month = this.getMonth(), await this.loadRecordTableContent() }
      if(e=='quarter') { this.month = null, this.year = null, this.quarter = this.getQuarter(), await this.loadRecordTableContent() }
      if(e=='year') { this.month = null, this.quarter = null, this.year = this.getYear(), await this.loadRecordTableContent() }
    },
    // 获取0点时间戳
    getTimestamp() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let date = yDate.getDate();
      let day = year+'-'+month+'-'+date
      let time = (new Date(new Date(day).toLocaleDateString())).getTime();
      return time
    },
    // 时间戳转日期格式
    timeStFormat(timestamp) {
      let yDate = new Date(timestamp)
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let date = yDate.getDate();
      month = month < 10 ? '0' + month : month;
      date = date < 10 ? '0' + date : date;
      return year+'-'+month+'-'+date
    },
    // 季度格式提交参数处理
    quarterHanlder() {
      let comp = this.quarter
      let sp = comp.split('-')
      let format = sp[0]+'-'+'Q'+sp[1]
      return format
    },
    // 月份格式初始化
    getMonth() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      month = month < 10 ? '0' + month : month;
      return year+'-'+month
    },
    // 季度格式初始化
    getQuarter() {
      let yDate = new Date()
      let year = yDate.getFullYear();
      let month = yDate.getMonth()+1;
      let quarter = ''
      if(month<=3) {
        quarter = 1
      }else if(month>3 && month<=6) {
        quarter = 2
      }else if(month>6 && month<=9) {
        quarter = 3
      }else if(month>9 && month<=12) {
        quarter = 4
      }
      return year+'-'+quarter
    },
    // 年份格式初始化
    getYear() {
      let yDate = new Date()
      return (yDate.getFullYear()).toString()
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.getRecordList()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.getRecordList()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  mounted() {
    this.loadGroupList()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 380px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
/deep/.el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
  box-shadow: none;
}
.jobs {
  height: 100%;
}
.man-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
    .menu-list {
      overflow-y: auto;
      padding-bottom: 10px;
      .menu-title {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        background: #F4F4F4;
        padding-left: 20px;
        i {
          font-size: 14px;
          padding-right: 5px;
        }
      }
      .tree-menu {
        font-size: 14px;
        cursor: pointer;
        .tree-menu-title {
          line-height: 40px;
          padding-left: 20px;
          transition: all .4s ease;
          color: #202020;
          user-select: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .icon-triangle {
            margin-right: 5px;
            transition: all .4s ease;
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid #202020;
            border-top: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-radius: 3px;
          }
          .arrow {
            transform: rotate(90deg);
          }
          .unarrow {
            transform: rotate(0deg);
          }
          .flag {
            display: inline-block;
            border-radius: 4px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            padding: 0 5px;
            margin-right: 5px;
          }
          .flag-allow {
            background-color: rgba(69, 170, 242, 0.15);
            border-color: rgba(69, 170, 242, 0.15);
            color: rgb(69, 170, 242);
          }
          .flag-onlyread {
            background-color: rgba(178, 190, 195, 0.15);
            border-color: rgba(178, 190, 195, 0.15);
            color: #b2bec3;
          }
        }
        .tree-menu-title:hover {
          border-radius: 5px;
        }
        .tree-config {
          line-height: 40px;
        }
        .config-title {
          padding-left: 50px;
          padding-right: 20px;
          color: #202020;
          user-select: none;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .class-icon {
          display: inline-block;
          margin-right: 8px;
        }
        .bright {
          background: #409EFF;
          color: #FFF;
        }
      }
    }
  }
  .table {
    flex: 1;
    overflow-x: auto;
  }
  .operate {
    &:hover {
      color: #409eff;
    }
  }
}
.search {
  height: 63px;
  padding: 0 10px;
  display: flex;
  .title-label {
    width: 80px;
    color: #797979;
    text-align: right;
    font-size: 14px;
    line-height: 40px;
    box-sizing: border-box;
    margin-right: 10px;
    &::before {
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
  }
  .select-label {
    font-size: 14px;
    margin-right: 10px;
    color: #767779;
  }
}
</style>

<style lang="less">
.el-tree-node__content {
  height: 40px;
}
.el-dropdown-menu__item {
  padding: 0;
  > div {
    padding: 0 20px;
  }
}
</style>